// @z-index space: 80-90
@mixin c-divider-text($base:&) {

  & {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  @at-root {
    #{$base}__line {
      flex: 1;
      height: 1px;
      background: var(--divider-line-color, grey);
    }

    #{$base}__inner {
      padding: 0 10px;
    }

    #{$base}__element_modifier {
    }
  }
}
