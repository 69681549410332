// @z-index space: 80-90
@mixin c-app-layout($base:&) {

  & {
    display: flex;
  }

  @at-root {
    #{$base}__sidebar {
    }

    #{$base}__content {
    }

    #{$base}__element_modifier {
    }
  }
}
