/* You can add global styles to this file, and also import other style files */

@import 'assets/scss/apply';
@import "assets/dashkit/scss/theme";

:root {
  --bs-gutter-x: 30px;

}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
