// @z-index space: 80-90
@mixin c-auth-layout($base:&) {

  & {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @at-root {
    #{$base}__content {
      margin-top: auto;
      margin-bottom: auto;
    }

    #{$base}__logo {
      display: block;
      width: 200px;
      margin: 30px auto;

    }

    #{$base}__content {
    }

    #{$base}__copy {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
