//
// close.scss
// Extended from Bootstrap
//

// Small

.close-sm {
  font-size: $close-font-size-sm;
}
